<template>
  <Dialog
    class="v1-file-view-dialog"
    :draggable="false"
    :closable="false"
    :visible="display"
    @hide="okClick"
  >
    <template #header>
      <!--      <h3>{{ title }}</h3>-->
      <div class="grid nogutter" style="width: 100%">
        <div class="col">
          <h3>{{ title }}</h3>
        </div>
        <div class="col text-right">
          <Button
            icon="pi pi-times"
            class="p-button-rounded p-button-text p-button-plain no-box-shadow"
            @click="okClick"
          />
        </div>
      </div>
    </template>

    <div class="grid nogutter flex-column" style="height: 500px">
      <div v-if="loading" class="col text-center mt-5">
        <ProgressSpinner style="width: 100px; height: 100px" strokeWidth="2" />
      </div>

      <div v-if="fileUrl !== ''" class="col">
        <iframe
          :src="fileUrl"
          width="100%"
          style="height: 100%; border: none"
        />
      </div>

      <div v-if="error" class="col mt-5">
        <div class="grid nogutter flex-column">
          <div class="col text-center">
            <i class="fas fa-exclamation-triangle fa-3x c2m-error-color"></i>
          </div>
          <div class="col text-center error-text">
            There was an error loading the requested file.<br />Please contact
            support if you need assistance.
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <Button label="Ok" class="no-box-shadow v1-button" @click="okClick" />
    </template>
  </Dialog>
</template>

<script lang="ts">
import { defineComponent, inject, onMounted, ref, watch } from "vue";

import { Api } from "@/api";
import { PostFileDownload } from "@/api/services/v1Payloads";

export interface FileUploadConfig {
  endpoint: string;
  documentId: string;
  ownerId: string;
}

export default defineComponent({
  name: "FileViewDialog",
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "View Document",
    },
    filePath: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const $api = inject("$api") as Api;

    const loading = ref(false);
    const error = ref(false);
    const fileUrl = ref();

    // watchers
    watch(
      () => props.filePath,
      () => {
        console.log(`here: ${props.filePath}`);
        fetchFile();
      }
    );

    // local methods
    const fetchFile = async () => {
      loading.value = true;
      error.value = false;
      fileUrl.value = "";

      if (props.filePath !== "") {
        try {
          const req: PostFileDownload = {
            file_path: props.filePath,
          };

          const { data }: { data: Blob } = await $api.v1.getFile(req);

          let blobString = await data.text();
          if (blobString === "Blob ids not found") {
            error.value = true;
            return;
          }

          // fileUrl.value = window.URL.createObjectURL(data);
          // fileUrl.value = (
          //   window.URL ||
          //   window.webkitURL ||
          //   window ||
          //   {}
          // ).createObjectURL(data);

          const reader = new FileReader();
          const blob = new Blob([data], { type: "application/pdf" });
          reader.onload = () => {
            fileUrl.value = reader.result;
          };
          reader.readAsDataURL(blob);
        } catch (e) {
          console.error(e);
          error.value = true;
        } finally {
          loading.value = false;
        }
      }
    };

    // ui methods
    const okClick = () => {
      emit("ok");
    };

    // lifecycle hooks
    onMounted(() => {
      console.log(`fileviewdialog mounted: ${props.filePath}`);
      fetchFile();
    });

    return {
      // refs
      loading,
      error,
      fileUrl,
      // methods
      okClick,
    };
  },
});
</script>

<style lang="scss" scoped></style>
