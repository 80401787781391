
import { defineComponent, inject, onMounted, ref, watch } from "vue";

import { Api } from "@/api";
import { PostFileDownload } from "@/api/services/v1Payloads";

export interface FileUploadConfig {
  endpoint: string;
  documentId: string;
  ownerId: string;
}

export default defineComponent({
  name: "FileViewDialog",
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "View Document",
    },
    filePath: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const $api = inject("$api") as Api;

    const loading = ref(false);
    const error = ref(false);
    const fileUrl = ref();

    // watchers
    watch(
      () => props.filePath,
      () => {
        console.log(`here: ${props.filePath}`);
        fetchFile();
      }
    );

    // local methods
    const fetchFile = async () => {
      loading.value = true;
      error.value = false;
      fileUrl.value = "";

      if (props.filePath !== "") {
        try {
          const req: PostFileDownload = {
            file_path: props.filePath,
          };

          const { data }: { data: Blob } = await $api.v1.getFile(req);

          let blobString = await data.text();
          if (blobString === "Blob ids not found") {
            error.value = true;
            return;
          }

          // fileUrl.value = window.URL.createObjectURL(data);
          // fileUrl.value = (
          //   window.URL ||
          //   window.webkitURL ||
          //   window ||
          //   {}
          // ).createObjectURL(data);

          const reader = new FileReader();
          const blob = new Blob([data], { type: "application/pdf" });
          reader.onload = () => {
            fileUrl.value = reader.result;
          };
          reader.readAsDataURL(blob);
        } catch (e) {
          console.error(e);
          error.value = true;
        } finally {
          loading.value = false;
        }
      }
    };

    // ui methods
    const okClick = () => {
      emit("ok");
    };

    // lifecycle hooks
    onMounted(() => {
      console.log(`fileviewdialog mounted: ${props.filePath}`);
      fetchFile();
    });

    return {
      // refs
      loading,
      error,
      fileUrl,
      // methods
      okClick,
    };
  },
});
